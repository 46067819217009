import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "./Components/Common/Loader";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./Components/Layout/PageNotFound";
import { ProtectedRoute } from "./Components/Layout/ProtectedRoute";
import LayoutComp from "./Components/Layout/LayoutComp";
import { GetCMSData } from "./services/cms";
import { cmsItemSelector } from "./redux/cms";
import { useDispatch, useSelector } from "react-redux";
import { getDataFromAppSetting } from "./lib/helper";

const HomePage = lazy(() => import("./Components/Screens/HomePage"));
const AboutUs = lazy(() => import("./Components/Screens/AboutUs"));
const Common = lazy(() => import("./Components/Screens/Common"));
const ContactUs = lazy(() => import("./Components/Screens/ContactUs"));
const AllImages = lazy(() => import("./Components/Screens/AllImages"));
const AllService = lazy(() => import("./Components/Screens/AllService"));
const Threading = lazy(() => import("./Components/Screens/Services/Threading"));
const AntiWrinkles = lazy(() =>
  import("./Components/Screens/Services/AntiWrinkles")
);
const CndShellac = lazy(() =>
  import("./Components/Screens/Services/CndShellac")
);
const DermalFillers = lazy(() =>
  import("./Components/Screens/Services/DermalFillers")
);
const Facials = lazy(() => import("./Components/Screens/Services/Facials"));
const HairCare = lazy(() => import("./Components/Screens/Services/HairCare"));
const LashesAndBrows = lazy(() =>
  import("./Components/Screens/Services/LashesAndBrows")
);
const ManicureAndPadicure = lazy(() =>
  import("./Components/Screens/Services/ManicureAndPadicure")
);
const Massages = lazy(() => import("./Components/Screens/Services/Massages"));
const Microblading = lazy(() =>
  import("./Components/Screens/Services/Microblading")
);
const Miscellaneous = lazy(() =>
  import("./Components/Screens/Services/Miscellaneous")
);
const WaxingAndHotWax = lazy(() =>
  import("./Components/Screens/Services/WaxingAndHotWax")
);
const DynamicContentComponent = lazy(() =>
  import("./Components/Screens/Dynamic/DynamicContentComponent")
);
const Login = lazy(() => import("./Components/Screens/Login"));

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const { cmsData } = useSelector(cmsItemSelector);
  const [mainData, setMainData] = useState([
    { id: 1, title: "Threading", image: "" },
    { id: 2, title: "Lashes & Brows", image: "" },
    { id: 3, title: "Facials", image: "" },
    { id: 4, title: "Massages", image: "" },
    { id: 5, title: "Waxing & Hot Wax", image: "" },
    { id: 6, title: "Manicure & Pedicure", image: "" },
    { id: 7, title: "CND, Shellac, Gel-Polish", image: "" },
    { id: 8, title: "Microblading", image: "" },
    { id: 9, title: "Dermal Fillers", image: "" },
    { id: 10, title: "Anti Wrinkles", image: "" },
    { id: 11, title: "Hair Care", image: "" },
    { id: 12, title: "MISCELLANEOUS", image: "" },
  ]);
  const logoutUser = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };
  const isAuthenticated = localStorage.getItem("token"); // Check if token is present in localStorage

  useEffect(() => {
    if (isAuthenticated) {
      const decodedJwt = jwt_decode(isAuthenticated);
      const expirationTime = decodedJwt?.exp * 1000;
      if (expirationTime < Date.now()) {
        logoutUser();
      }
    }
  }, [location]);
  useEffect(() => {
    dispatch(GetCMSData());
  }, []);
  useEffect(() => {
    if (cmsData && cmsData?.items && cmsData?.items?.length > 0) {
      const data = getDataFromAppSetting(
        cmsData?.items,
        "key_name",
        "allservice"
      );
      if (data && data?.length > 0) {
        setMainData(data);
      }
    }
  }, [cmsData]);
  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      {cmsData?.loading === true && <Loader />}
      <Routes>
        <Route path="/login" element={<Login />} />

        {/* Private routes that require authentication */}
        {isAuthenticated ? (
          <>
            <Route
              path="/"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={HomePage} />
              }
            />
            <Route
              path="/aboutus"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={AboutUs} />
              }
            />
            <Route
              path="/common"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={Common} />
              }
            />
            <Route
              path="/contactus"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={ContactUs} />
              }
            />
            <Route
              path="/allImages"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={AllImages} />
              }
            />
            <Route
              path="/allservice"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={AllService}
                />
              }
            />
            {mainData &&
              mainData?.length > 0 &&
              mainData?.map((page) => (
                <Route
                  key={`/${page.slug}`}
                  path={`/${page.slug}`}
                  element={
                    <LayoutComp
                      path={`/${page.slug}`}
                      pageSlug={page.slug}
                      pageData={page}
                      titlebar={"Dashboard Data"}
                      component={DynamicContentComponent}
                    />
                  }
                />
              ))}
            {/* <Route
              path="/threading"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={Threading} />
              }
            /> */}
            {/* <Route
              path="/lashes-and-brows"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={LashesAndBrows}
                />
              }
            />
            <Route
              path="/facials"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={Facials} />
              }
            />
            <Route
              path="/massages"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={Massages} />
              }
            />
            <Route
              path="/waxing-and-hot-wax"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={WaxingAndHotWax}
                />
              }
            />
            <Route
              path="/manicure-padicure"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={ManicureAndPadicure}
                />
              }
            />
            <Route
              path="/cnd-shellac"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={CndShellac}
                />
              }
            />
            <Route
              path="/microblading"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={Microblading}
                />
              }
            />
            <Route
              path="/dermal-fillers"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={DermalFillers}
                />
              }
            />
            <Route
              path="/anti-wrinkle"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={AntiWrinkles}
                />
              }
            />
            <Route
              path="/hair-care"
              element={
                <LayoutComp titlebar={"Dashboard Data"} component={HairCare} />
              }
            />
            <Route
              path="/miscellaneous"
              element={
                <LayoutComp
                  titlebar={"Dashboard Data"}
                  component={Miscellaneous}
                />
              }
            /> */}
          </>
        ) : (
          <Route path="/login" element={<Navigate to="/login" replace />} />
        )}

        {/* 404 route */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
