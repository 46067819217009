import React from "react";
import { Button, CircularProgress } from "@mui/material";

function CustomButton({
  color,
  variant,
  disabled,
  loading,
  loaderSize,
  ...props
}) {
  return (
    <Button
      color={color}
      variant={variant}
      disabled={disabled || loading}
      {...props}
    >
      {props.children}{" "}
      {loading ? (
        <CircularProgress
          color="inherit"
          size={loaderSize}
          style={{ marginLeft: 10 }}
        />
      ) : null}
    </Button>
  );
}

export default CustomButton;

CustomButton.defaultProps = {
  color: "buttonNormal",
  variant: "contained",
  disabled: false,
  loading: false,
  loaderSize: 24,
};
