// TO REDUCE TIME OFFSET FOR TIME DIFF.
function adjustForTimezone(date) {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() + timeOffsetInMS);
  return date;
}

export function formatDate(d, format = "dd/mm/yy", adjustOffset = false) {
  if (!d) {
    return "";
  }
  let monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = new Date(d);
  if (adjustOffset) {
    date = adjustForTimezone(new Date(d));
  }
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var h = date.getHours();
  var hUTC = date.getUTCHours();
  var m = date.getMinutes();
  var timeFormat = "AM";
  var s = date.getSeconds();
  var ms = date.getMilliseconds();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  if (h <= 11 && m < 60) {
    timeFormat = "AM";
  } else if (h >= 12) {
    timeFormat = "PM";
  }

  if (format?.includes("12")) {
    h = h % 12;
    h = h ? h : 12; // the hour '0' should be '12'
    m = m;
  }
  if (h < 10) {
    h = "0" + h;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (s < 10) {
    s = "0" + s;
  }
  if (ms < 10) {
    ms = "0" + ms;
  }

  let formatNeed = format?.toLowerCase();

  if (formatNeed == "dd-mm-yy") {
    return (d = dd + "-" + mm + "-" + yyyy.toString()?.slice(2, 4));
  } else if (formatNeed == "dd/mm/yy") {
    return (d = dd + "/" + mm + "/" + yyyy.toString()?.slice(2, 4));
  } else if (formatNeed == "dd-mm-yyyy") {
    return (d = dd + "-" + mm + "-" + yyyy);
  } else if (formatNeed == "dd/mm/yyyy") {
    return (d = dd + "/" + mm + "/" + yyyy);
  } else if (formatNeed == "yyyy-mm-dd hh:mm:ss.ms") {
    return (d =
      yyyy + "-" + mm + "-" + dd + " " + h + ":" + m + ":" + s + "." + ms);
  } else if (formatNeed == "dd-mm-yyyy hh:mm:ss.ms") {
    return (d =
      dd + "-" + mm + "-" + yyyy + " " + h + ":" + m + ":" + s + "." + ms);
  } else if (formatNeed == "dd-mm-yyyy hh:mm") {
    return (d = dd + "-" + mm + "-" + yyyy + " " + h + ":" + m);
  } else if (formatNeed == "dd-mm-yyyy hh:mm 12h") {
    return (d =
      dd + "-" + mm + "-" + yyyy + " " + h + ":" + m + " " + timeFormat);
  } else if (formatNeed == "yyyy-mm-dd") {
    return (d = yyyy + "-" + mm + "-" + dd);
  } else if (formatNeed == "hh:mm 12h") {
    return (d = h + ":" + m + " " + timeFormat);
  } else if (formatNeed == "iso") {
    return date.toISOString();
  } else if (formatNeed == "utc") {
    return date.toUTCString();
  }
}

export function validDate(date, compareDate = null) {
  // const currentDate = new Date();
  // const incomingDate = new Date(date)
  // let crr = formatDate(currentDate);
  // let incr = formatDate(incomingDate);
  // if(crr == incr) {
  //   return false
  // }else{
  //   let status = currentDate > incomingDate
  //   return status;
  // }
  let current_date = new Date();
  if (compareDate) {
    current_date = new Date(compareDate);
  }
  let incoming_date = new Date(date);
  if (current_date.getTime() < incoming_date.getTime()) {
    return true;
  } else {
    return false;
  }
}

export const errorMessage = (response) => {
  try {
    return (
      response?.response?.data?.message ||
      response?.data?.response?.message ||
      response?.data?.message ||
      response?.message ||
      "Something went wrong"
    );
  } catch (e) {
    return e?.message || "Something went wrong";
  }
};

export const logoutuser = () => {
  localStorage?.removeItem("token");
  window.location.href = "/login";
};

export const getSelectFieldSelected = (payload, state, prefix) => {
  let entries = Object.entries(payload);
  let filteredData = state?.items?.filter((val) => {
    if (val[prefix] == entries[0][1]) {
      return val;
    }
  });
  return {
    fieldName: entries[0][0],
    data: filteredData[0],
  };
};

export const getSelectFieldSelectedValue = (data, state, prefix) => {
  let filteredData = state?.items?.filter((val) => {
    if (val[prefix] == data) {
      return val;
    }
  });
  return filteredData[0];
};

export const getFilterdData = (state, key, machingKeyValue) => {
  try {
    let filteredData = state?.filter((item) => {
      return item[key] == machingKeyValue;
    });
    return filteredData;
  } catch (e) {
    return [];
  }
};

export const crypt = (salt = "crypt", text) => {
  const textToChars = (text) => text?.split("")?.map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    ?.split("")
    ?.map(textToChars)
    ?.map(applySaltToChar)
    ?.map(byteHex)
    .join("");
};

export const decrypt = (salt = "crypt", encoded) => {
  const textToChars = (text) => text?.split("")?.map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    ?.match(/.{1,2}/g)
    ?.map((hex) => parseInt(hex, 16))
    ?.map(applySaltToChar)
    ?.map((charCode) => String.fromCharCode(charCode))
    .join("");
};

export const getDataFromAppSetting = (arr, key, valueKey) => {
  let mainObj = {};
  if (arr?.length > 0) {
    arr.filter((data) => {
      if (data[key] === valueKey) {
        mainObj = {
          key_name: data?.key_name,
          key_value: data?.key_value,
          parent_key: data?.parent_key,
        };
      }
    });
    if (mainObj && mainObj?.key_value) {
      let mainObject = JSON.parse(mainObj?.key_value);
      return mainObject;
    }
  } else {
    return "N/A";
  }
};

export const getCmsIdFromData = (arr, key, valueKey) => {
  let mainObj = {};
  if (arr?.length > 0) {
    arr.filter((data) => {
      if (data[key] === valueKey) {
        mainObj = {
          key_name: data?.key_name,
          _id: data?._id,
          key_value: data?.key_value,
          parent_key: data?.parent_key,
        };
      }
    });
    return mainObj;
  } else {
    return "N/A";
  }
};
export const getDataFromContentFul = (arr) => {
  let mainObj = {};
  if (arr?.items?.length > 0) {
    arr?.items?.map((data) => {
      mainObj[data?.fields?.title] = `https:${data?.fields?.file?.url}`;
    });
    return mainObj;
  } else {
    return "N/A";
  }
};
