import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../MainAssets/index.css";
import HouseIcon from "@mui/icons-material/House";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ReactComponent as MainLogoIcon } from "../MainAssets/pixel-dot-white.svg";
import { getDataFromAppSetting, logoutuser } from "../../lib/helper";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { cmsItemSelector } from "../../redux/cms";
import { GetCMSData } from "../../services/cms";

export const mainAdminRoute = [
  {
    title: "HomePage",
    slug: "",
  },
  {
    title: "About Us",
    slug: "aboutus",
  },
  {
    title: "Common",
    slug: "common",
  },
  {
    title: "Contact Us",
    slug: "contactus",
  },
  {
    title: "All Service",
    slug: "allservice",
  },
  {
    title: "All Images",
    slug: "allImages",
  },
];
const LayoutComp = ({ component: ReactComponent, pageData }) => {
  const location = useLocation();
  const [mainData, setMainData] = useState([]);
  const { cmsData } = useSelector(cmsItemSelector);
  const navigate = useNavigate();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);
  useEffect(() => {
    dispatch(GetCMSData());
  }, []);
  useEffect(() => {
    if (cmsData && cmsData?.items && cmsData?.items?.length > 0) {
      const data = getDataFromAppSetting(
        cmsData?.items,
        "key_name",
        "allservice"
      );
      if (data && data?.length > 0) {
        setMainData([...mainAdminRoute, ...data]);
      }
    }
  }, [cmsData]);
  return (
    <>
      <div>
        <div className="cs-main-layout">
          <div ref={ref} className="cs-layout-sidebar">
            <div className="company-details-main">
              <div className="company-main-logo">
                <MainLogoIcon className="cs-main-logo" />
              </div>
              <div className="company-profile-details">
                Welcome to our admin panel !
              </div>
            </div>
            <div className="cs-layout-sidebar-desktop">
              {mainData &&
                mainData?.length > 0 &&
                mainData?.map((menuItem, index) => {
                  return (
                    <Link
                      reloadDocument
                      key={`menu${index}desktop`}
                      to={`/${menuItem?.slug}`}
                    >
                      <div className="cs-layout-menu">
                        <button
                          className={`${
                            location.pathname === menuItem?.slug
                              ? "cs-layout-button-active"
                              : ""
                          } cs-layout-button`}
                        >
                          <div className="cs-layout-menu-text">
                            <HouseIcon className="dashboard-layout-sidemenu-icon" />
                            <span className="dashboard-layout-sidemenu-name">
                              {menuItem?.title}
                            </span>
                          </div>
                        </button>
                      </div>
                    </Link>
                  );
                })}
              <div
                className="cs-layout-menu cs-logout-button"
                key="layout-new-desktop-logout"
              >
                <button
                  className="cs-layout-button"
                  onClick={() => logoutuser()}
                >
                  <div className="cs-layout-menu-text">
                    <ExitToAppIcon className="dashboard-layout-sidemenu-icon" />
                    <span className="dashboard-layout-sidemenu-name">
                      Logout
                    </span>
                  </div>
                </button>
              </div>
            </div>
            <div className="cs-layout-sidebar-mobile">
              {mainData &&
                mainData?.map((menuItem, index) => {
                  return (
                    <Link
                      reloadDocument
                      key={`menu${index}desktop`}
                      to={`/${menuItem?.slug}`}
                    >
                      <div
                        className="cs-layout-menu"
                        key={`menu${index}mobile`}
                      >
                        <button
                          className={`${
                            location.pathname === menuItem?.slug
                              ? "cs-layout-button-active"
                              : ""
                          } cs-layout-button`}
                        >
                          <div className="cs-layout-menu-text">
                            <HouseIcon className="dashboard-layout-sidemenu-icon" />
                            <span className="dashboard-layout-sidemenu-name">
                              {menuItem.title}
                            </span>
                          </div>
                        </button>
                      </div>
                    </Link>
                  );
                })}
              <div
                className="cs-layout-menu cs-logout-button"
                key="layout-new-mobile-logout"
              >
                <button
                  className="cs-layout-button"
                  onClick={() => logoutuser()}
                >
                  <div className="cs-layout-menu-text">
                    <ExitToAppIcon className="dashboard-layout-sidemenu-icon" />
                    <span className="dashboard-layout-sidemenu-name">
                      Logout
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <Box
            sx={{
              "@media only screen and (min-width: 1025px)": {
                paddingLeft: `calc(${width}px + 2rem)`,
              },
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
            component="div"
            className="cs-layout-children"
          >
            <ReactComponent pageData={pageData} />
          </Box>
        </div>
      </div>
    </>
  );
};

export default LayoutComp;
