import axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../config/ApiConfig";
import { errorMessage } from "../lib/helper";
import { setLoading, setCmsData, setCmsDataError } from "../redux//cms";

import { STATUS_200, STATUS_300, STATUS_400 } from "../Components/constants";
import { getObjectFromStore } from "./Storage";

export const GetCMSData = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.getCms}`);
      if (resp?.status >= 200 && resp?.status < 300) {
        dispatch(setCmsData(resp?.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      setCmsDataError({
        isError: true,
        message: errorMessage(e),
      });
      dispatch(setLoading(false));
    }
  };
};

export const AddCmsData = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        let token = getObjectFromStore("token");
        let config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        // dispatch(setLoading(true));
        const response = await axios.post(
          `${ApiConfig.addCms}`,
          payload,
          config
        );
        if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
          toast.success(` You have Successfully Added Data.`, {
            autoClose: 2500,
            className: "toast-message",
            position: toast.POSITION.TOP_RIGHT,
          });
          GetCMSData();
          return true;
        } else {
          throw response;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoading(false));
        return false;
      }
    };
  }
};

export const UpdateCmsData = (payload, contentId) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        let token = getObjectFromStore("token");
        let config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        const response = await axios.put(
          `${ApiConfig.updateCms}/${contentId}`,
          payload,
          config
        );
        if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
          toast.success(`You have Successfully Updated.`, {
            autoClose: 2500,
            className: "toast-message",
            position: toast.POSITION.TOP_RIGHT,
          });
          dispatch(GetCMSData());
          dispatch(setLoading(false));
          return true;
        } else {
          throw response;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLoading(false));
        return false;
      }
    };
  }
};
