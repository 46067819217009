import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Common/Buttons/CustomButton";

function PageNotFound() {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token"); // Check if token is present in localStorage

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        backgroundColor: "#F4FAFE",
        justifyContent: "center",
        alignItems: "center",
      }}
      component="main"
    >
      <Box sx={{ backgroundColor: "white", borderRadius: 20, padding: 3 }}>
        404 Page Not Found
        <CustomButton
          onClick={() => {
            navigate(isAuthenticated ? "/" : "/login");
          }}
          sx={{
            padding: "5px 10px",
            marginLeft: 5,
            fontSize: "12px",
            width: "110px",
            bgcolor: "buttonInfo.main",
          }}
        >
          {isAuthenticated ? "Go To HomePage" : "Go To Login"}
        </CustomButton>
      </Box>
    </Box>
  );
}

export default PageNotFound;
