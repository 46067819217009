export const BASE_URL_MAIN = process.env.REACT_APP_API_URL;

let ApiConfig = {
  token: null,
  loginUser: `${BASE_URL_MAIN}api/login`,
  addCms: `${BASE_URL_MAIN}api/cms`,
  updateCms: `${BASE_URL_MAIN}api/cms`,
  getCms: `${BASE_URL_MAIN}api/cms`,
};

export default ApiConfig;
