import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "./login";
import appSettingReducer from "./appSetting";
import cmsReducer from "./cms";
export const store = configureStore({
  reducer: {
    appSettingData: appSettingReducer,
    loginUserData: loginReducer,
    cmsData: cmsReducer,
  },
});
