import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cmsData: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
};

export const cmsSlice = createSlice({
  name: "cmsData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.cmsData.loading = action.payload;
    },
    setCmsData: (state, action) => {
      state.cmsData.items = action.payload;
      state.cmsData.error = {
        isError: false,
        message: "",
      };
    },
    setCmsDataError: (state, action) => {
      state.cmsData.items = null;
      state.cmsData.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setCmsData, setCmsDataError } = cmsSlice.actions;

export default cmsSlice.reducer;

export const cmsItemSelector = (state) => state.cmsData;
