import Route from "./route";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import { theme } from "./config/mui-theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./AxiosInterceptor";
import "./Components/MainAssets/login.css";
import "./Components/MainAssets/layout.css";
import "./Components/MainAssets/index.css";
import "./Components/Common/style.css";
import axios from "axios";
import { getObjectFromStore } from "./services/Storage";

function App() {
  // const requestHandler = async (request) => {
  //   let token = getObjectFromStore("token");
  //   if (token) {
  //     request.headers = {
  //       Authorization: `${token}`,
  //     };
  //     return request;
  //   } else {
  //     return request;
  //   }
  // };
  // axios.interceptors.request.use((request) => requestHandler(request));
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Router>
            <Route />
          </Router>
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
